<template>
  <b-card>
    <b-row>
      <b-col cols="4">
        <h4 class="text-primary">صورة الجولة :</h4>
        <b-img :src="data.item.image" class="w-100 h-75 pt-2  "  />
      </b-col>
      <b-col cols="4">
        <h4 class="text-primary">المناطق :</h4>
        <ul>
          <li v-for="item in data.item.points">
            {{ item.area }}
          </li>
        </ul>
      </b-col>
      <b-col cols="4">
        <h4 class="text-primary">التصنيفات :</h4>
        <ul>
          <li v-for="item in data.item.tourCategories">
            {{ item.name }}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol,BImg } from "bootstrap-vue";
export default {
  props: {
    data: Object,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BImg
  },
};
</script>
